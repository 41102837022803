import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../Theme/styles'

export default class extends React.Component {
  render() {
    return (
      <div id="msPricing" style={{ backgroundColor: '#FF914D', padding: 50 }}>
        <Container style={styles.pricingforMSPContainer}>
          <p style={styles.pricingAsHeading}>One Price, No Surprises</p>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
              <br />
              <p style={styles.pricingMS$}>$100</p>
              <p style={styles.pricingMS$Info}>/ Meeting Booked</p>
              <p style={styles.pricingMS$Info}>
                $250 one time setup fee, contact us to
                <br /> get the Q4 Discount
              </p>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <p style={styles.pricingMSDetailHeading}>What you will Get?</p>
              <br />
              <p>
                ✔️ Qualified Meetings on Demand
                <br />
                ✔️ Keep Filling your Sales Teams Calendars with Qualified Appointments
                <br />
                ✔️ Weekly Reports
                <br />
                ✔️ Consulting for your Business Growth for FREE
                <br />
                ✔️ 24 hours support
                <br />
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
