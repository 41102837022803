const navyblue = '#000022'
const green = '#00FF00'
const black = '#000000'
const white = '#fff'
const gray = '#A9A9A9'

const theme = {
    navyblue,
    green,
    black,
    white,
    gray
}


export default theme