import React from "react"
import { Container,Row,Col } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
          <div id="msPricing" style={{backgroundColor:'#FF914D',padding:50}}>
            <Container style={styles.pricingforMSPContainer}>
                <p style={styles.pricingAsHeading}>One Price, No Surprises</p>
              <Row>
<Col lg={6} md={6} sm={6} xs={12}>
<br />
<p style={styles.pricingMS$}>
$200</p>
<p style={styles.pricingMS$Info}>/ Location / Month</p>
<p style={styles.pricingMS$Info}>$350 one time setup fee, contact us to<br /> get the special Christmas Discount</p>
</Col>
<Col lg={6} md={6} sm={6} xs={12}>
<p style={styles.pricingMSDetailHeading}>What you will Get?</p><br />
<p>
✔️ Setting your sms service
<br />
✔️ Managing it<br />
✔️ Building your customer lists by ads and multiple options so that whenver you send sms you can reach more customers<br />
✔️ Consulting for your Business Growth<br />
✔️ 24 hours support<br />
</p>
</Col>
              </Row>
            </Container>
              </div>
        )
    }
}
