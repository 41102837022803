import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='how-it-works' style={styles.benefitsContainer}>
                <Container>
                    <h1 style={styles.howWeWorkHeading}>How It Works
</h1>
                    <p style={styles.howweworkPara}>We save your thousands of hours of and efforts to avoid training sales closers for months that's where we come's in, to provide you ready to go plug and play Sales Closing Teams
</p>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/how-we-work-logo-1.webp')} />
                            <p style={styles.benefitsSteps}>Step 1</p>
                            <p style={styles.benefitsHeadlines}>30 Mins Discovery Call
</p>
                            <p style={styles.benefitsPara}>30 Mins Discovery Call helps us to figure out your needs and requirements.
You can reserve your a slot by pressing Hire Closers Now button on this page to get 30 Mins Call Booking.</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/how-we-work-logo-2.png')} />
                            <p style={styles.benefitsSteps}>Step 2</p>
                            <p style={styles.benefitsHeadlines}>High Efficiency Scalibity Audit
</p>
                            <p style={styles.benefitsPara}>We plan together on the best course of action to hit your sales goals

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/how-we-work-logo-3.png')} />
                            <p style={styles.benefitsSteps}>Step 3</p>
                            <p style={styles.benefitsHeadlines}>Data Driven Matching Process
</p>
                            <p style={styles.benefitsPara}>We match you with the Closers who have solid command in your Industry



</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/how-we-work-logo-4.png')} />
                            <p style={styles.benefitsSteps}>Step 4</p>
                            <p style={styles.benefitsHeadlines}>360 Pre-Deployment Training

</p>
                            <p style={styles.benefitsPara}>We train our Sales Closers on all aspects of your offerings, Brand and Audience and so on...



</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/how-we-work-logo-5.png')} />
                            <p style={styles.benefitsSteps}>Step 5</p>
                            <p style={styles.benefitsHeadlines}>Commission-Based


</p>
                            <p style={styles.benefitsPara}>Our closers start closing for you on a performance-basis



</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/how-we-work-logo-6.png')} />
                            <p style={styles.benefitsSteps}>Step 6</p>
                            <p style={styles.benefitsHeadlines}>On-Going Training & Performance Checks  & Accountability


</p>
                            <p style={styles.benefitsPara}>We train them on the go according to the outcomes and will provide report of every single minute of on your fingure tips



</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}