import React from "react"
import { NavbarM, FrontBannerMS, Contact, BenefitsofMS, HowItWorksMS, Footer, PricingMS } from "."

export default class extends React.Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <NavbarM navName='Magic SMS' />
                   <FrontBannerMS />
                   <BenefitsofMS />
                     <HowItWorksMS />
                        <PricingMS />
                      <Contact rest={true} />
                 <Footer />
            </div>
        )
    }
}
