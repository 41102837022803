import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='benefits' style={styles.industryContainer}>
                <Container>
                    <h1 style={styles.industryHeading}>Hire Professional Closers With Experience<br />
In Your Industry</h1>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/industry-logo-1.png')} />
                            <p style={styles.industryHeadlines}>Professional Services
</p>
                            <p style={styles.benefitsPara}>Choose from the options of Top Closers who are having experience in your Industry and Niche

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/industry-logo-2.png')} />
                            <p style={styles.industryHeadlines}>Education</p>
                            <p style={styles.benefitsPara}>Choose from a list of top-closers with experience selling educational products from the world’s top personality brands

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/industry-logo-3.png')} />
                            <p style={styles.industryHeadlines}>Investing

</p>
                            <p style={styles.benefitsPara}>Choose from a list of top-closers with specialized knowledge in selling financial investments





</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/industry-logo-4.png')} />
                            <p style={styles.industryHeadlines}>Consulting
</p>
                            <p style={styles.benefitsPara}>Choose from a list of top-closers with a background in selling consulting offers



</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/industry-logo-5.png')} />
                            <p style={styles.industryHeadlines}>Events

</p>
                            <p style={styles.benefitsPara}>Choose from a list of top-closers to sell event tickets, sell at events, or for post-event follow-ups



</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/industry-logo-6.png')} />
                            <p style={styles.industryHeadlines}>Others

</p>
                            <p style={styles.benefitsPara}>Have something else you offer? Inquire now to find out if we can connect you with a closer who can help



</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}