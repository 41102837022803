import React from "react"

export default class extends React.Component {
    render() {
        return (
            <div id='closers' style={{height:800}}>
                <h1>Closers</h1>
            </div>
        )
    }
}