import React from "react"
import { Navbar, Contact, Footer, Services, HomeFrontBanner } from "."

export default class extends React.Component {
    render() {
        return (
            <div id='home'>
                <Navbar />
                <HomeFrontBanner />
                <Services history={this.props.history} />
                <Contact />
                <Footer />
            </div>
        )
    }
}

