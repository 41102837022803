import React from "react"
import { Nav, Navbar, Container, Col, NavDropdown, Image } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
  render() {
    const url  = this.props.navUrl?this.props.navUrl:'magic-sms'
    return (
      <Navbar style={{backgroundColor:'#000'}} sticky="top" className='navbarBG' collapseOnSelect style={styles.navbarBG} expand="lg">
        <Container>
          <Navbar.Brand style={styles.logo2} href="/">{this.props.navName} <span style={{fontSize:10,color:'#00FF00'}}>by NC</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav >
              <Nav.Link href="/" style={styles.whiteText}>Home</Nav.Link>
              <Nav.Link href={`/${url}/#benefits`} style={styles.whiteText}>Benefits</Nav.Link>
              <Nav.Link href={`/${url}/#how-it-works`} style={styles.whiteText}>How It Works</Nav.Link>
              <Nav.Link href={`/${url}/#msPricing`} style={styles.whiteText}>Pricing</Nav.Link>
                  <Nav.Link href="/#services" style={styles.whiteText}>Services</Nav.Link>
              <Nav.Link href={`/${url}/#contact`} style={styles.whiteText}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >
    )
  }
}
