import React from "react"
import { Navbar, FrontBanner, CtaButton, Benefits, HowWeWork, Closers, Industry, Testinomials, FAQS, Contact, Footer, BookContainer } from "."
import ctaButton from "./cta-button"

export default class extends React.Component {
    render() {
        return (
            <div id='sc' style={{ textAlign: 'center' }}>
                <Navbar />
                <FrontBanner />
                <Testinomials />
                <Benefits />
                <hr />
                <HowWeWork />
                <CtaButton title="Hire Closers Now" />
                <hr />
                <BookContainer />
                <hr />
                <Industry />
                <FAQS />
                <CtaButton title="Hire Closers Now" />
                <Contact />
                <Footer />
            </div>
        )
    }
}