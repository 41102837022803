import React from "react"
import { Navbar, ShowMeResultsBTN } from "."
import { Col, Row, Container } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
            <div style={styles.ASFrontBanner} className='asFrontBanner'>
                <Container>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <img src={require('../media/fast-websites.png')} style={{ width: '100%' }} />
                        </Col>
                        <Col md={6} sm={12} xs={12} style={{ margin: 'auto' }}>
                            <div>
                                <h1 style={styles.ASFrontBannerHeading}>Get Appointments Booked On Lightning Fast Speed</h1>
                                <p style={styles.ASFrontBannerPara}>Wait is finally over to get appointments whether you want 20 - 100 interested appointemnts / Day, It's all possible by Us</p>
                                <ShowMeResultsBTN />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}