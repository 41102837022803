import React from "react"
import { Button } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    openCalendly() {
        window.open('https://calendly.com/theahsanusman/discovery-call');
    }
    render() {
      let className = `ctabutton ${this.props.rest?'warmShadow':'greenShadow'}`
        return (
        <Button style={styles.ctaButton} onClick={this.openCalendly} className={className}>{this.props.title}</Button>
        )
    }
}
