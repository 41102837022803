import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
            <div style={styles.ASFrontBanner}>
                <Container>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <img src={require('../media/stop.png')} />
                        </Col>
                        <Col md={6} sm={12} xs={12} style={{ margin: 'auto' }}>
                            <div>
                                <h1 style={styles.ASFrontBannerHeading}>Your One Stop Shop For All of Your Sales Needs</h1>
                                <p style={styles.ASFrontBannerPara}>Wait is finally over to get more sales whether you want closers to deals for you or appointments / leads</p>
                                {/* <ShowMeResultsBTN /> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}