import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='benefits' style={styles.benefitsContainer}>
                <Container>
                    <h1 style={styles.benefitsHeading}>Benefits of Using Closing Services</h1>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-1.png')} />
                            <p style={styles.benefitsHeadlines}>Highest Conversions means more Clients</p>
                            <p style={styles.benefitsPara}>Our sales talent work on your leads on a performance basis. This means more sales with less risk.</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-2.png')} />
                            <p style={styles.benefitsHeadlines}>Extreme
Accountability</p>
                            <p style={styles.benefitsPara}>Receive all details like call recording, status of each lead, notes of interactions on your figher tips</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-3.png')} />
                            <p style={styles.benefitsHeadlines}>Remote Sales Teams of Top Performers Only</p>
                            <p style={styles.benefitsPara}>We do trainings of Closers  on the go so that they can always be above average  to close more of your leads

</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-4.png')} />
                            <p style={styles.benefitsHeadlines}>SkyRocket Your Sales Quickly and Predictibly
</p>
                            <p style={styles.benefitsPara}>Get the best of the Best Closing Team, to get results in just a matter of days

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-5.png')} />
                            <p style={styles.benefitsHeadlines}>ALL on US
</p>
                            <p style={styles.benefitsPara}>Hire a team of closrs without inveting your an hour because we will manage all the things for YOU.

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-6.png')} />
                            <p style={styles.benefitsHeadlines}>FREE Business Consulting

</p>
                            <p style={styles.benefitsPara}>Get the best of the best secret tips and strategies to scale quickly and shift on to the next level

</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}