import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='benefits' style={styles.benefitsContainer}>
                <Container>
                    <h1 style={styles.benefitsHeading}>Benefits</h1>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/speed-icon-5.jpg')} />
                            <p style={styles.benefitsHeadlines}>NO LIMITs</p>
                            <p style={styles.benefitsPara}>Get as many leads as you want no LIMIT</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-5.png')} />
                            <p style={styles.benefitsHeadlines}>Scalable
    </p>
                            <p style={styles.benefitsPara}>Scalable without investing any extra money</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-1.png')} />
                            <p style={styles.benefitsHeadlines}>Quality Leads</p>
                            <p style={styles.benefitsPara}>Get Warm Leads that shown interest in your products/services</p>
                        </Col>
                    </Row>
                    {/* <Row style={{ marginTop: 50 }}>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-4.png')} />
                            <p style={styles.benefitsHeadlines}>SkyRocket Your Sales Quickly and Predictibly
</p>
                            <p style={styles.benefitsPara}>Get the best of the Best Closing Team, to get results in just a matter of days

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-5.png')} />
                            <p style={styles.benefitsHeadlines}>ALL on US
</p>
                            <p style={styles.benefitsPara}>Hire a team of closrs without inveting your an hour because we will manage all the things for YOU.

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/benefits-logo-6.png')} />
                            <p style={styles.benefitsHeadlines}>FREE Business Consulting

</p>
                            <p style={styles.benefitsPara}>Get the best of the best secret tips and strategies to scale quickly and shift on to the next level

</p>
                        </Col>
                    </Row> */}
                </Container>
            </div>
        )
    }
}