import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styles from '../Theme/styles'

export default class extends React.Component {
  render() {
    return (
      <div id="how-it-works" style={styles.benefitsContainer}>
        <Container>
          <h1 style={styles.howWeWorkHeading}>How It Works</h1>
          {/* <p style={styles.howweworkPara}>
            We save your thousands of hours of and efforts to avoid training
            sales closers for months that's where we come's in, to provide you
            ready to go plug and play Sales Closing Teams
          </p> */}
          <Row>
            <Col lg="4" md="4" sm="4">
              <img
                style={styles.benefitsLogo}
                src={require('../media/how-we-work-logo-1.webp')}
              />
              <p style={styles.benefitsSteps}>Step 1</p>
              <p style={styles.benefitsHeadlines}>30 Mins Discovery Call</p>
              <p style={styles.benefitsPara}>
                30 Mins Discovery Call helps us to figure out your needs and
                requirements. You can reserve your slot by pressing Get Started
                button{' '}
              </p>
            </Col>
            <Col lg="4" md="4" sm="4">
              <img
                style={styles.benefitsLogo}
                src={require('../media/how-we-work-logo-3.png')}
              />
              <p style={styles.benefitsSteps}>Step 2</p>
              <p style={styles.benefitsHeadlines}>
               Setting Up Things For You
              </p>
              <p style={styles.benefitsPara}>
                After knowing your requirements of Meetings we will setup CRM and our callers perfetctly align A/c to your Goals to Take Off
              </p>
            </Col>
            <Col lg="4" md="4" sm="4">
              <img
                style={styles.benefitsLogo}
                src={require('../media/how-we-work-logo-5.png')}
              />
              <p style={styles.benefitsSteps}>Step 3</p>
              <p style={styles.benefitsHeadlines}>
                Take Off
              </p>
              <p style={styles.benefitsPara}>
                In the First 4 Days of getting Started we will be started to deliver you Mettings Booked on your Sales Teams Calendar 
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: 50 }}>
            <Col lg="4" md="4" sm="4">
              <img
                style={styles.benefitsLogo}
                src={require('../media/Antialiasfactory-Jewelry-Partnership.ico')}
              />
              <p style={styles.benefitsSteps}>Step 4</p>
              <p style={styles.benefitsHeadlines}>
                Closing Appointments
              </p>
              <p style={styles.benefitsPara}>
                Your Sales Team will Close Deals 
              </p>
            </Col>
            <Col lg="4" md="4" sm="4">
              <img
                style={styles.benefitsLogo}
                src={require('../media/3749976.png')}
              />
              <p style={styles.benefitsSteps}>Step 5</p>
              <p style={styles.benefitsHeadlines}>Counting ROI</p>
              <p style={styles.benefitsPara}>
                See the Multiple Xs of Returns on your Investment as promised
              </p>
            </Col>
            <Col lg="4" md="4" sm="4">
              <img
                style={styles.benefitsLogo}
                src={require('../media/speedometer-icon-png-4.jpg')}
              />
              <p style={styles.benefitsSteps}>Step 6</p>
              <p style={styles.benefitsHeadlines}>
                Accelerate
              </p>
              <p style={styles.benefitsPara}>
                As per your Requirements we will ramp up the Meetings to generate as Much ROI as possible because the more you make the more we make $$$! WIN WIN SITUATIONS!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
