import React from "react"
import { Button } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    openCalendly() {
        window.open('https://calendly.com/theahsanusman/free-discovery-call');
    }
    render() {
        return (
        <Button style={styles.asctaButton} onClick={this.openCalendly} className="ctabutton">{this.props.title}</Button>
        )
    }
}