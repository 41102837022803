import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='benefits' style={styles.benefitsContainer}>
                <Container>
                    <h1 style={styles.benefitsHeading}>Benefits</h1>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/on-demand.png')} />
                            <p style={styles.benefitsHeadlines}>Get Sales Meetings on Demand</p>
                            <p style={styles.benefitsPara}>Get As Much Sales Meetings As you Want with Potential Clients that are already interested in your service</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/default 1.png')} />
                            <p style={styles.benefitsHeadlines}>Easiest
    </p>
                            <p style={styles.benefitsPara}>NO MORE TIME WASTING ON OUTREACHINGs, just Focus on Closing Clients as many as you want and serve What you Best At</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/3200985.png')} />
                            <p style={styles.benefitsHeadlines}>Pay For What You Get</p>
                            <p style={styles.benefitsPara}>Forget OutReaches / Costly Ads that Charges you whether you get qualified meetings or not, with NC you'll only Pay when you get meetings booked</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
