import React from "react"
import { Button } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    openCalendly() {
        window.open('https://drive.google.com/file/d/1vj19N63he_etI9T7FZYBQqdTAEreKckZ/view?usp=sharing');
    }
    render() {
        return (
            <Button style={styles.downloadBTN} onClick={this.openCalendly} className="ctabutton">* Download Now
            <br />It's FREE *</Button>
        )
    }
}