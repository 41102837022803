import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"
import { BookDownloadBTN } from "."

export default class extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col md={6} sm={6} style={{ textAlign: 'right' }}>
                        <img style={styles.bookContainerImage} src={require('../media/book-cover.png')} />
                    </Col>
                    <Col md={6} sm={6} style={{ margin: 'auto', textAlign: (window.innerWidth < 893) ? 'center' : 'left' }}>
                        <div style={styles.bookContainerCTA}>
                            <p style={styles.bookContainerHeading}>Do you want to know How to 10X your Sales?</p>
                            <p>You will discover how to 10x your sales and also you will get to know the results that people are achieving with it and testinomials as well.
                            ALL THE BEST</p>
                            <BookDownloadBTN />
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}