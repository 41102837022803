import React from 'react';
import { Main, SC, MagicSMS ,MagicMeetings } from "./Components"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Main} />
        <Route path='/sc' component={SC} />
        <Route path='/magic-sms' component={MagicSMS} />
        <Route path='/magic-meetings' component={MagicMeetings} />
        <Route component={_ => <h1>404 Not Found</h1>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
