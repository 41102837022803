import React from "react"
import { Carousel,  } from "react-bootstrap"

export default class extends React.Component {
    render() {
        return (
            <Carousel style={{width:'100%'}}>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={require("../media/a_2.png")}
      alt="First slide"
    />

  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={require("../media/a_1.png")}
      alt="Third slide"
    />

  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
    src={require("../media/a_3.png")}
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>
        )
    }
}
