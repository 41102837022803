import React from "react"
import { Button } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    move() {
        window.location.replace('/as#testiomialsforAS')
    }
    render() {
        return (
            <Button style={styles.showMeResultsBTN} onClick={this.move} className='ctabutton'>Show Me the Results</Button>
        )
    }
}