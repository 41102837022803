import React from "react"
import { Image, Col, Row, Container } from "react-bootstrap"
import styles from "../Theme/styles"
import CTAButton from "./cta-button"

export default class extends React.Component {
    render() {
        return (
            <Container id='scHome' style={styles.fronbannerContainer}>
                <Row>
                    <Col lg={5} md={5} sm={5}>
                        <Image style={styles.frontbannerImage} src={require('../media/4898097.jpg')} rounded />
                    </Col>
                    <Col lg={7} md={7} sm={7} style={styles.frontBannerActionSideContainer}>
                        <div>
                        <p style={styles.frontBannerHeading}>Convert your Leads into $$$</p>
                        <p style={styles.frontBannerPara}>Supreme Brands and personality Brands hire our all-time ready commission-based sales closing teams to save their time while making more sales!</p>
                        <CTAButton title="Hire Closers Now" />
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}