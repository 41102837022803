import React from "react"
import styles from "../Theme/styles"
import { Container } from "react-bootstrap"

export default class extends React.Component {
    render() {
        return (
            <div style={styles.footerContainer}>
                <Container>
                    <hr />
                    <p>© 2020 Ninja Closers, All rights reserved.</p>
                </Container>
            </div>
        )
    }
}