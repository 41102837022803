import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='faqs' style={styles.faqsContainer}>
                <Container>
                    <h1 style={styles.faqsHeading}>FAQs</h1>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/faqs.png')} />
                            <p style={styles.benefitsHeadlines}>Where the Closers are from?
</p>
                            <p style={styles.benefitsPara}>USA, UK and UAE

</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/faqs.png')} />
                            <p style={styles.benefitsHeadlines}>What's your Closing Ratio?
</p>
                            <p style={styles.benefitsPara}>It Depends upon your quality of leads. Are they warm or hot?
If they are marketed properly then even we have gone to 40% closing ratio</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/faqs.png')} />
                            <p style={styles.benefitsHeadlines}>How much commissions do you charge?
</p>
                            <p style={styles.benefitsPara}>It depends upon your Offer Kindly book a call 🤙  to know more: <a href="https://calendly.com/theahsanusman/free-discovery-call">BOOK</a>


                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/faqs.png')} />
                            <p style={styles.benefitsHeadlines}>Do you work purely on the commission basis?

</p>
                            <p style={styles.benefitsPara}>YES, if your leads are warm or hot then we work purely on the commissions basis. If your leads are cold then it's not fully commision based kindly BOOK a call to discuss in detail



</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/faqs.png')} />
                            <p style={styles.benefitsHeadlines}>Do you have any testimonials?

</p>
                            <p style={styles.benefitsPara}>Yes, we do how many of them you want us to send you?



</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/faqs.png')} />
                            <p style={styles.benefitsHeadlines}>Will Closer work fulltime?


</p>
                            <p style={styles.benefitsPara}>YES if that's your requirements are



</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}