import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../Theme/styles'
import { CtaButton } from '.'

export default class extends React.Component {
  render() {
    return (
      <div id="contact" style={styles.contactContainer}>
        <Container>
          <p style={styles.contactHeading}>Get In Touch</p>
          <hr />
          <Row>
            <Col md={6} sm={12} xs={12}>
              <p style={styles.contactSideSubHeading}>
                Book Discovery and Consultation Call with an Expert for FREE
              </p>
              <CtaButton title="Book Now" rest={this.props.rest} />
            </Col>
            <Col
              md={6}
              sm={12}
              xs={12}
              style={{ textAlign: 'center', margin: 'auto' }}
            >
              <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>
                Message Us
              </h3>
              <a href="mailto:info@ahsanusman.com">
                <img
                  style={styles.contactIcon}
                  src={require('../media/icon-email-512.png')}
                />
              </a>
              {/* <a
                href="https://join.skype.com/invite/hnq7PdnLSI11"
                target="blank"
              >
                <img
                  style={styles.contactIcon}
                  src={require('../media/Skype.png')}
                />
              </a> */}
              <a href="https://linkedin.com/in/theahsanusman" target="blank">
                <img
                  style={styles.contactIcon}
                  src={require('../media/linkedin-512.png')}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
