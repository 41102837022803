import React from "react"
import { Navbar, ASFrontBanner, Contact, ASCTABTN, TestinpmailsAS, BenefitsOfAS, HowItWorksOfAS, Footer, PricingForAs } from "."
import { Col, Row, Container } from "react-bootstrap"

export default class extends React.Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <Navbar />
                <ASFrontBanner />
                <BenefitsOfAS />
                <HowItWorksOfAS />
                <ASCTABTN title='Schedule a Call NOW' />
                <PricingForAs />
                <TestinpmailsAS />
                <ASCTABTN title='Schedule a Call NOW' />
                <Contact />
                <Footer />
            </div>
        )
    }
}