import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
            <div  style={styles.testinomialContainer}>
                <Container>
                    <p id="testinomials" style={styles.testinomialHeading}>Brands that are using Closing Service</p>
                    <Row>
                        <Col style={styles.testinomialEachContainer} md="4" sm="6" xs="12">
                            <img style={styles.testinomialEachContainerImage} src={require('../media/4898163.png')} />
                            <p style={styles.testinomialEachContainerHeading}>7 FIGURE CEO & FOUNDER; e-COMMERCE COACH TO THOUSANDS OF STUDENTS THAT LEFT THEIR 9-5 JOB BEHIND THEM.</p>
                            <p style={styles.testinomialEachContainerPersonName}>KEVIN DAVID</p>
                            <p style={styles.testinomialEachContainerCredebility}>Founder at KEVIN DAVID</p>
                        </Col>
                        {
                            window.innerWidth<692?<hr />:''
                        }
                        <Col style={styles.testinomialEachContainer} md="4" sm="6" xs="12">
                            <img style={styles.testinomialEachContainerImage} src={require('../media/4898162.svg')} />
                            <p style={styles.testinomialEachContainerHeading}>7 FIGURE CEO & FOUNDER; e-COMMERCE COACH TO THOUSANDS OF STUDENTS THAT LEFT THEIR 9-5 JOB BEHIND THEM.</p>
                            <p style={styles.testinomialEachContainerPersonName}>PENG JOON  </p>
                            <p style={styles.testinomialEachContainerCredebility}>Founder at Smobble</p>
                        </Col>
                        {
                            window.innerWidth<692?<hr />:''
                        }
                        <Col style={styles.testinomialEachContainer} md="4" sm="4" xs="12">
                            <img style={styles.testinomialEachContainerPersonImage} src={require('../media/4968937.png')} />
                            <p style={styles.testinomialEachContainerHeading}>7 FIGURE CEO & FOUNDER; e-COMMERCE COACH TO THOUSANDS OF STUDENTS THAT LEFT THEIR 9-5 JOB BEHIND THEM.</p>
                            <p style={styles.testinomialEachContainerPersonName}>Dan Dasilva</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}