import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"


export default class extends React.Component {
    render() {
        return (
            <div id='benefits' style={styles.benefitsContainer}>
                <Container>
                    <h1 style={styles.benefitsHeading}>Benefits</h1>
                    <Row>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/on-demand.png')} />
                            <p style={styles.benefitsHeadlines}>Get Customers on Demand</p>
                            <p style={styles.benefitsPara}>Get your customers in the door as you send them just a single SMS. It simply means you can order customers at your command.</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/default 1.png')} />
                            <p style={styles.benefitsHeadlines}>Easiest
    </p>
                            <p style={styles.benefitsPara}>You don't have to do any technical things, you just have to pull out your phone and send just a text SMS, that's all!</p>
                        </Col>
                        <Col lg='4' md='4' sm='4'>
                            <img style={styles.benefitsLogo} src={require('../media/3200985.png')} />
                            <p style={styles.benefitsHeadlines}>Cost Efficient</p>
                            <p style={styles.benefitsPara}>Target your existing customers and making them to visit and order again and again, in less than the annual member of premium Netflix</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
