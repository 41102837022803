import React from "react"
import { Container } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
            <Container style={styles.pricingforASPContainer}>
                <p style={styles.pricingAsHeading}>Pricing</p>
                <div className="pricingforASPContainer">
                    <p style={styles.pricingAsSubHeading}>Pay as you go</p>
                    <p style={styles.pricingAsPricingPoint}>$11.15 / Lead</p>
                    <p style={styles.pricingPara}>One Time Setup Fee $300<br />Book a call to check that are there any discounts available</p>
                </div>
            </Container>
        )
    }
}