import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
            <div id='services' style={styles.servicesContainer}>
                <Container>
                    <p style={styles.servicesHeading}>Services</p>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <div style={styles.servicesEachContainer}>
                                <p style={styles.servicesEachContainerHeading}>Sales Closing</p>
                                <p>Are you looking for going to next step whether from 6 figures to 7 figures and do you want more conversions then it's defintely for you!</p>
                                <button style={styles.servicesLearnMore} onClick={_ => { window.location.replace('/sc/#scHome') }} className='ctabutton'>Learn More</button>
                            </div>
                        </Col>
                        {/* <Col md={6} sm={12} xs={12}>
                            <div style={styles.servicesEachContainer}>
                                <p style={{ ...styles.servicesEachContainerHeading, marginTop: window.innerWidth < 768 ? 40 : 0 }}>Appointment Setting</p>
                                <p>
                                    Are you looking for an appointment setter who can get the appointments from your leads for YOU?
                </p>
                                <button style={styles.servicesLearnMore} onClick={_ => { window.location.replace('/as') }} className='ctabutton'>Learn More</button>
                            </div>
                        </Col> */}
                         {/* <Col md={6} sm={12} xs={12}>
                            <div style={styles.servicesEachContainer}>
                                <p style={{ ...styles.servicesEachContainerHeading, marginTop: window.innerWidth < 768 ? 40 : 0 }}>Magic SMS</p>
                                <p>
                                    Best Way to Re-Target Customers
                </p>
                                <button style={styles.servicesLearnMore} onClick={_ => { window.location.replace('/magic-sms') }} className='ctabutton'>Learn More</button>
                            </div>
                        </Col> */}
                        <Col md={6} sm={12} xs={12}>
                            <div style={styles.servicesEachContainer}>
                                <p style={{ ...styles.servicesEachContainerHeading, marginTop: window.innerWidth < 768 ? 40 : 0 }}>Magic Meetings</p>
                                <p>
                                    Your Reliable Qualified Sales Meetings Provider
                </p>
                                <button style={styles.servicesLearnMore} onClick={_ => { window.location.replace('/magic-meetings') }} className='ctabutton'>Learn More</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}