import theme from "./index"

const styles = {
    whiteText: {
        color: theme.white
    },
    logo: {
        fontFamily: 'GlacialIndifference',
        color: '#00FF00',
        fontSize: 25,
        fontWeight: 600
    },
    logo2: {
        fontFamily: 'GlacialIndifference',
        color: '#FF914D',
        fontSize: 25,
        fontWeight: 600
    },
    frontbannerImage: {
        width: '100%'
    },
    fronbannerContainer: {
        paddingTop: 75
    },
    frontBannerHeading: {
        fontSize: 40,
        fontFamily: 'GlacialIndifferenceBold',
    },
    frontBannerPara: {
        fontFamily: 'GlacialIndifference',
        color: theme.gray,
        fontSize: 14,
    },
    frontBannerActionSideContainer: {
        margin: 'auto',
        textAlign: (window.innerWidth < 893) ? 'center' : 'left'
    },
    asctaButton: {
        width: 225,
        fontFamily: 'GlacialIndifferenceBold',
        height: 45,
        borderRadius: 45,
        backgroundColor: theme.navyblue,
        color: theme.white
    },
    ctaButton: {
        width: 175,
        fontFamily: 'GlacialIndifferenceBold',
        height: 45,
        borderRadius: 45,
        backgroundColor: theme.navyblue,
        color: theme.white
    },
    testinomialContainer: {
        marginTop: 75,
        height: window.innerWidth > 769 ? 500 : '100%',
        textAlign: 'center',
        backgroundColor: theme.navyblue
    },
    testinomialHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 30,
        textAlign: 'center',
        color: theme.white,
        paddingTop: 55,
        marginBottom: 75
    },
    testinomialEachContainer: {
        width: '100%'
    },
    testinomialEachContainerImage: {
        width: 150,
        marginBottom: 25,
        height: 50
    },
    testinomialEachContainerPersonImage: {
        width: 75,
        height: 75,
        borderRadius: 150,
        marginBottom: 25
    },
    testinomialEachContainerHeading: {
        color: theme.gray,
        fontSize: 14
    },
    testinomialEachContainerPersonName: {
        color: theme.white,
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 20
    },
    testinomialEachContainerCredebility: {
        color: theme.white
    },
    benefitsLogo: {
        width: 100
    },
    benefitsContainer: {
        paddingTop: 100,
        textAlign: 'center',
        marginBottom:50
    },
    benefitsHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        marginBottom: 50
    },
    benefitsHeadlines: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 19,
        marginTop: 25
    },
    benefitsPara: {
        color: theme.gray
    },
    howweworkPara: {
        color: theme.gray,
        marginBottom: 45
    },
    howWeWorkHeading: {
        fontFamily: 'GlacialIndifferenceBold',
    },
    benefitsSteps: {
        textAlign: 'center',
        color: theme.green,
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 18,
        marginTop: 5
    },
    industryContainer: {
        marginTop: 100,
        paddingTop: 45,
        backgroundColor: theme.navyblue,
        textAlign: 'center',
        height: window.innerWidth > 769 ? 750 : '100%',
    },
    industryHeading: {
        color: theme.white,
        fontFamily: 'GlacialIndifferenceBold',
        marginBottom: 45
    },
    industryHeadlines: {
        fontFamily: 'GlacialIndifferenceBold',
        color: theme.white,
        fontSize: 22,
        marginTop: 10
    },
    faqsHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42,
        marginBottom: 45
    },
    faqsContainer: {
        paddingTop: 75,
        textAlign: 'center'
    },
    contactHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42,
    },
    contactContainer: {
        paddingTop: 100,
        textAlign: 'center',
        marginBottom:25
    },
    contactSideSubHeading: {
        textAlign: 'center',
        fontFamily: 'GlacialIndifference',
    },
    contactIcon: {
        width: '50px',
        marginRight: 25
    },
    footerContainer: {
        backgroundColor: '#000',
        color: '#fff',
        textAlign: 'center',
        height:58
    },
    bookContainerImage: {
        width: window.innerWidth > 994 ? 450 : '100%'
    },
    bookContainerHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42,
    },
    downloadBTN: {
        width: 250,
        fontFamily: 'GlacialIndifferenceBold',
        height: 60,
        borderRadius: 45,
        backgroundColor: theme.navyblue,
        color: theme.white
    },
    showMeResultsBTN: {
        width: 210,
        fontFamily: 'GlacialIndifferenceBold',
        height: 45,
        lineHeight: '30px',
        borderRadius: 45,
        backgroundColor: theme.navyblue,
        color: theme.white
    },
    ASFrontBanner: {
        paddingTop: 50,
        backgroundColor: theme.black,
        textAlign: 'center',
        // height:window.innerWidth<?500:'100%'
    },
    ASFrontBannerHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42,
        color: theme.white
    },
    ASFrontBannerPara: {
        fontFamily: 'GlacialIndifference',
        fontSize: 14,
        color: theme.gray
    },
    ASTestinomialHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42
    },
    ASTestinomialsContainer: {
        textAlign: 'center',
        paddingTop: 75
    },
    servicesHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42
    },
    servicesContainer: {
        textAlign: 'center',
        marginTop: 70,
        marginBottom: 70
    },
    servicesEachContainer: {
        textAlign: 'center'
    },
    servicesEachContainerHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 22
    },
    servicesLearnMore: {
        width: 250,
        fontFamily: 'GlacialIndifferenceBold',
        height: 60,
        borderRadius: 45,
        backgroundColor: theme.navyblue,
        color: theme.white
    },
    pricingforMSPContainer: {
        paddingTop: 25,
        paddingBottom: 25,
        backgroundColor:'#fff'
    },

    pricingAsHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 42
    },
    pricingAsSubHeading: {
        fontFamily: 'GlacialIndifferenceBold',
        fontSize: 25
    },
    pricingAsPricingPoint: {
        fontFamily: 'GlacialIndifference',
        fontSize: 20
    },
    pricingPara: {
        color: theme.gray,
        fontFamily: 'GlacialIndifference',
        fontSize: 14
    },
    pricingMS$:{
      fontSize:35,
      fontFamily:'GlacialIndifferenceBold'
    },
    pricingMS$Info:{
      fontSize:14,
        fontFamily: 'GlacialIndifference'
    },
    pricingMSDetailHeading:{
      fontSize:25,
      fontFamily:'GlacialIndifferenceBold'
    },
    MSBenfefitsIMG:{
      width:'50%'
    }
}

export default styles
