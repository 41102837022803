import React from "react"
import { Container, Carousel } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
    render() {
        return (
            <Container id="testiomialsforAS" style={styles.ASTestinomialsContainer}>
                <p style={styles.ASTestinomialHeading}>Testinomials</p>
                <p style={styles.ASFrontBannerPara}>These are screenshots of appointemtns and prospects that we are getting if you want to see it in the video then scedile the call with an expert</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/Annotation 2020-09-08 191549.png')}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/1.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/2.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/3.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/4.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/5.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/6.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/7.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/8.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require('../media/9.png')}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>
        )
    }
}