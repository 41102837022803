import React from 'react'
import {
  NavbarM,
  FrontBannerForMeetings,
  Contact,
  CtaButton,
  BenefitsofMM,
  HowItWorksMM,
  Footer,
  PricingMM,
} from '.'

export default class extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <NavbarM navName="Magic Meetings" navUrl="magic-meetings" />
        <FrontBannerForMeetings />
        <BenefitsofMM />
        <CtaButton title="Get Started" rest={false} />
        <HowItWorksMM />
        <CtaButton title="Get Started" rest={true} />
        <br />
        <br />
        <br />
        <PricingMM />
        <Contact rest={true} />
        <Footer />
      </div>
    )
  }
}
