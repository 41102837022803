import React from "react"
import { Nav, Navbar, Container, Col, NavDropdown, Image } from "react-bootstrap"
import styles from "../Theme/styles"

export default class extends React.Component {
  render() {
    return (
      <Navbar style={{backgroundColor:'#000'}} sticky="top" className='navbarBG' collapseOnSelect style={styles.navbarBG} expand="lg">
        <Container>
          <Navbar.Brand style={styles.logo} href="/">Ninja Closers</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav >
              <Nav.Link href="/#home" style={styles.whiteText}>Home</Nav.Link>
              <Nav.Link href="/#services" style={styles.whiteText}>Services</Nav.Link>
              <Nav.Link href="/#contact" style={styles.whiteText}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >
    )
  }
}
